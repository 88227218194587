import { WebContext } from "../../contexts/web/web-context-provider";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Screen } from "../../components/screen/screen";
import { colors } from "../../assets/constants/colors";
import { TfiBriefcase } from "react-icons/tfi";
import { useContext, useMemo, useCallback, useState } from "react";
import "../../App.css";
import { Tabs } from "../../components/tabs/tab";

const TABS = {
  MOBILE: "iOS/Android Mobile Apps",
  DATA: "Machine Learning & Data Science",
};

/**
 * Portfolio Screen
 */
export const Portfolio = () => {
  const { onPressOpenLinkInNewTab, onPressOpenLink } = useContext(WebContext);
  const [tab, setTab] = useState("");

  const portfolioData = useMemo(() => {
    return {
      mobileApps: [
        {
          name: "CellarTracker",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://cellartracker.com" }),
          image: require("../../assets/images/portfolio/app-icons/cellartracker-icon-mobile-app-ios-android.png"),
          descriptions: [
            "August 2023 — Present",
            "React Native Specialist",
            "Wine Cellar Management & Wine Reviews",
            "Overhaul/Conversion with React Native",
          ],
        },
        {
          name: "Landscape Supply",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://landscapesupply.app/" }),
          image: require("../../assets/images/portfolio/app-icons/Landscape-Supply-App-Logo-Shop-Marketplace-Online.png"),
          descriptions: [
            "December 2020 — Present",
            "Buy/Sell Landscape Supplies",
            "Mobile App & Web Dashboard",
            "Stripe Payments API Integration",
          ],
        },
        {
          name: "Demosphere",
          onPress: () => onPressOpenLink({ link: "/portfolio/demosphere" }),
          image: require("../../assets/images/portfolio/app-icons/demosphere_icon_dark.png"),
          descriptions: [
            "March 2022 — May 2023",
            "Lead React Native Engineer",
            "Sports League Management Platform",
            "Overhaul of Legacy Codebase",
          ],
        },
        {
          name: "Finequities",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://finequities.com/" }),
          image: require("../../assets/images/portfolio/app-icons/finequities_icon_dark_bg.png"),
          descriptions: [
            "October 2021 — February 2022",
            "Junior React Native Developer",
            "First Full-Time Software Job",
            "Learned from Senior Engineer",
          ],
        },
        {
          name: "STEM Tech Network",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://stemtechnetwork.com" }),
          image: require("../../assets/images/portfolio/app-icons/stem_tech_network_icon.png"),
          descriptions: [
            "March 2022 — February 2023",
            "Personal Passion Project",
            "Social Learning Network",
            "Solo Full-Stack Unicorn Developer",
          ],
        },
        {
          name: "Origami Risk",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://www.origamirisk.com/" }),
          image: require("../../assets/images/new-home/origami.png"),
          descriptions: [
            "June 2023 — August 2023",
            "Mobile Engineer",
            "Insurance Software Company",
            "Overhaul/Conversion with React Native",
          ],
        },
        {
          name: "Marriage DAO",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://marriagedao.vip/" }),
          image: require("../../assets/images/portfolio/app-icons/marriage-dao-blockchain-ios-android-app-etherium-marriage-polygon-web3.png"),
          descriptions: [
            "February 2023",
            "Miami Hack Week Competition",
            "Officiate Your Marriage on the Blockchain",
            "React Native App / React Website",
          ],
        },
        {
          name: "Ask Away",
          link: "https://padverbny.com/portfolio/ask-away/",
          image: require("../../assets/images/portfolio/app-icons/ask_away_max_maxim_padverbny_archlife_industries_mobile_app_developer.png"),
          descriptions: [
            "January 2023 — March 2023",
            "Ask Yes/No Questions",
            "Firebase Authentication",
            "Neon PostgresQL DB & Hasura Backend",
          ],
        },
        {
          name: "@TeachersLounge",
          onPress: () =>
            onPressOpenLinkInNewTab({ link: "https://teacherslounge.club/" }),
          image: require("../../assets/images/portfolio/app-icons/teachers-lounge-club-app-network.png"),
          descriptions: [
            "December 2022 — April 2023",
            "Teachers & Educators Network",
            "Built Custom Mono-Repo",
            "Solo Full-Stack Unicorn Developer",
          ],
        },
        // {
        //     "name": "UTTO Mobile",
        //     // "onPress": () => onPressOpenLink({ link: "/portfolio/client-customer-management-manager-organizer-mobile-iphone-ios-android-app-tool-for-gym-trainers-training-club" }),
        //     "image": require('../../assets/images/portfolio/app-icons/app.png'),
        //     "descriptions": [
        //         "December 2020 — February 2021",
        //         "Made Repairs to Legacy Codebase",
        //         "Improved the Map's Responsiveness",
        //         "Gained Experience with Bluetooth"
        //     ],
        // },
        {
          name: "ADP Gym",
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/client-customer-management-manager-organizer-mobile-iphone-ios-android-app-tool-for-gym-trainers-training-club",
            }),
          image: require("../../assets/images/portfolio/app-icons/ADP_Gym_Logo.png"),
          descriptions: [
            "October 2020 — December 2020",
            "Project for a Gym in Rhode Island",
            "Gym Members can Track Workouts",
            "Members can Make Payments",
          ],
        },
        {
          name: "BetterReads",
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/readrr-a-platform-to-discover-track-and-share-new-books",
            }),
          image: require("../../assets/images/new-home/better-reads-book-open-icon-ryan-zernach-archlife.png"),
          descriptions: [
            "March 2020 — May 2020",
            "Track, Discover, & Share Books",
            "iOS App for Book Recommendations",
            "Includes Machine Learning Algorithms",
          ],
        },
      ],
      dataScience: [
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/airline-price-predictor-how-are-flight-prices-calculated",
            }),
          image: require("../../assets/images/portfolio/posts/Airline-Price-Predictions-Flights-Zernach-Machine-learning-algorithms-smart-ai-engineer-deploy-educated.com-The-Zernach-Foundation-Ryan-Zernach.png"),
          highlightColor: colors.blue,
          descriptions: ["CAN MY MODEL PREDICT THE PRICE OF YOUR NEXT FLIGHT?"],
        },
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/brainpower-the-future-of-communicating-with-technology",
            }),
          image: require("../../assets/images/portfolio/posts/Ryan_Zernach_Brain_Controlled_Web_Application_Zernach_Neurotech_Archlife_Industries.png"),
          highlightColor: colors.pink,
          descriptions: ["THE FUTURE OF COMMUNICATING WITH TECHNOLOGY"],
        },
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/global-glaciers-where-on-earth-are-they-located",
            }),
          image: require("../../assets/images/portfolio/posts/Where_on_earth_all_the_worlds_largest_glaciers_at_what_elevation.png"),
          highlightColor: colors.blue,
          descriptions: ["WHERE ON EARTH ARE ALL THE GLACIERS LOCATED?"],
        },
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/varieties-types-data-visualizations-charts-plots-graphs",
            }),
          image: require("../../assets/images/portfolio/posts/Data-Visualizations-Charts-Graphs-Ryan-Zernach-python-javascript-html.png"),
          highlightColor: colors.green,
          descriptions: ["A DIVERSE COLLECTION OF DATA VISUALIZATIONS & MAPS"],
        },
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/aws-certified-cloud-practitioner-study-guide",
            }),
          image: require("../../assets/images/portfolio/posts/aws/AWS_Certified_Cloud_Practitioner_Ryan_Zernach_Cloud_Foundations-Engineer-Computing-Databases-Remote.png"),
          highlightColor: colors.pink,
          descriptions: ["I AM AN AWS-CERTIFIED CLOUD PRACTITIONER"],
        },
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/tensorflow-developer-certification-study-guide",
            }),
          image: require("../../assets/images/portfolio/posts/tensorflow/Tensorflow-Study-Guide-Ryan-Zernach-Coursera-Credit-Certification.png"),
          highlightColor: colors.blue,
          descriptions: [
            "I AM A CERTIFIED TENSORFLOW DEVELOPER FOR MACHINE LEARNING & DEEP LEARNING",
          ],
        },
        {
          onPress: () =>
            onPressOpenLink({
              link: "/portfolio/data-robot-10x-data-science-academy-certification-guide",
            }),
          image: require("../../assets/images/portfolio/posts/datarobot/Data-Robot-10x-Data-Science-Academy-Certification-Guide-Notes-Study-Curriculum-Ryan-Zernach-blog.png"),
          highlightColor: colors.blue,
          descriptions: [
            "I AM A CERTIFIED DATAROBOT 10X MACHINE LEARNING PROFESSIONAL & DATA SCIENTIST",
          ],
        },
      ],
    };
  }, [onPressOpenLink, onPressOpenLinkInNewTab]);

  const BackButton = () => (
    <div
      className="hover"
      onClick={() => setTab("")}
      style={{
        alignItems: "center", // Center vertically
        backgroundColor: `${colors.darkGrey}99`,
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: `${colors.green}99`,
        borderRadius: "10px",
        paddingLeft: "5px",
        paddingRight: "5px",
        cursor: "pointer",
        alignSelf: "flex-start",
        width: "50%",
        marginBottom: "30px",
      }}
    >
      <p className="hFlexSmall" style={{ margin: 0 }}>
        ⬅ Back
      </p>
    </div>
  );

  const MobileApps = useCallback(
    () => (
      <>
        <Container style={{ marginTop: "5vh" }}>
          <BackButton />
          <Row>
            {portfolioData.mobileApps.map((app, index) => (
                       <Col
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={`index${index}`}
                className="mb-4 hover"
              >
                <div onClick={app?.onPress} style={{ cursor: "pointer" }}>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: 320 }}
                  >
                    <Image
                      src={app.image}
                      alt={app.name}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#1c1c1c",
                        boxShadow: "0px 0px 10px 0px #ffffff99",
                        borderRadius: "40px",
                      }}
                    />
                  </div>
                  <h3 style={{ marginTop: "2vh", color: "#ffffff" }}>
                    {app.name}
                  </h3>
                </div>
                <div style={{ marginTop: "2vh" }}>
                  {app.descriptions.map((desc, index) => (
                    <div key={`index${index}`}>
                      <p
                        style={{
                          color: "#ffffff99",
                          fontSize: "1.1rem",
                          marginRight: "-10px",
                          marginLeft: "-10px",
                        }}
                      >
                        {desc}
                      </p>
                    </div>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </>
    ),
    [portfolioData.mobileApps]
  );

  const DataScienceProjects = useCallback(
    () => (
      <>
        {/* <div style={{ height: "150px" }} /> */}
        {/* <h1>
          <span style={{ color: "#ffffff99" }}>
            {"Machine Learning & Data Science Projects"}
          </span>
        </h1>
        <h4>
          <span style={{ color: "#ffffff99" }}>{"All Built with Python"}</span>
        </h4> */}
        <Container style={{ marginTop: "5vh" }}>
          <BackButton />
          <Row>
            {portfolioData.dataScience.map((app, index) => (
              <Col
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={`index${index}`}
                className="mb-4 hover"
              >
                <div onClick={app?.onPress} style={{ cursor: "pointer" }}>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: 320 }}
                  >
                    <Image
                      src={app.image}
                      alt={app.name}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#1c1c1c",
                        boxShadow: `0px 0px 10px 0px ${
                          app?.highlightColor || "#ffffff"
                        }99`,
                        borderRadius: "40px",
                      }}
                    />
                  </div>
                  <h3 style={{ marginTop: "2vh", color: "#ffffff" }}>
                    {app.name}
                  </h3>
                </div>
                <div style={{ marginTop: "2vh" }}>
                  {app.descriptions.map((desc, index) => (
                    <div key={`index${index}`}>
                      <p
                        style={{
                          color: "#ffffff99",
                          fontSize: "1.1rem",
                          marginRight: "-10px",
                          marginLeft: "-10px",
                        }}
                      >
                        {desc}
                      </p>
                    </div>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </>
    ),
    [portfolioData.dataScience]
  );

  return (
    <Screen>
      <div>
        <TfiBriefcase
          color={`${colors.primary}99`}
          size={"100px"}
          style={{ marginBottom: "10px" }}
        />
        <h1 className="title">
          <span style={{ color: `${colors.primary}99` }}>{"PORTFOLIO"}</span>
        </h1>
      </div>
      <div style={{ height: !tab ? "50px" : "10px" }} />
      {!tab && (
        <Tabs tabs={TABS} tab={tab} setTab={setTab} icons={["📱", "🤖"]} />
      )}
      {tab === TABS.MOBILE && <MobileApps />}
      {tab === TABS.DATA && <DataScienceProjects />}
    </Screen>
  );
};

import { useMemo } from 'react';
import { colors } from "../../assets/constants/colors";
import { IoMdHelpBuoy } from "react-icons/io";
import { GiBrain, GiTargetArrows, GiNetworkBars, GiArchiveResearch, GiStairsGoal, GiTeamUpgrade } from "react-icons/gi";
import { CgSmartphoneChip } from "react-icons/cg";
import { TfiComments, TfiThought } from "react-icons/tfi";
import { TbZoomQuestion, TbTargetArrow } from "react-icons/tb";

export const useBiographyFunctions = () => {
    const ryanAgeInYears = useMemo(() => {
        const birthDate = new Date('1993-06-30T00:00:00.000Z');
        const currentDate = new Date();
        const ageInYears = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = currentDate.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
            return ageInYears - 1;
        }
        return ageInYears;
    }, [])

    const bradyAgeInYears = useMemo(() => {
        const birthDate = new Date('2018-04-23T00:00:00.000Z');
        const currentDate = new Date();
        const ageInYears = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = currentDate.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
            return ageInYears - 1;
        }
        return ageInYears;
    }, [])

    // const yearsUntilForty = useMemo(() => {
    //     const fortyDate = new Date('2033-06-30T00:00:00.000Z');
    //     const currentDate = new Date();
    //     const ageInYears = fortyDate.getFullYear() - currentDate.getFullYear();
    //     const monthDifference = fortyDate.getMonth() - currentDate.getMonth();
    //     if (monthDifference < 0 || (monthDifference === 0 && fortyDate.getDate() < currentDate.getDate())) {
    //         return ageInYears - 1;
    //     }
    //     return ageInYears;
    // }, [])

    const introductionBio = useMemo(() => {
        return [
            {
                text: `I follow my dreams, which has most recently led me to the island of Miami Beach, where I remotely work as an iOS/Android/Web Developer. I am a highly-organized React & React Native Engineer.`,
                image: require('../../assets/images/biography/Ryan_Zernach_Miami_Beach_Transylvanian_Hound_South_Pointe_Pier_South_Beach_2021_Mobile_App_Developer_Software_Engineer_Remote.png'),
                imageTitle: 'Ryan Zernach, Miami Beach, Florida',
                imageCaption: `Having run in several half marathons, I regularly go jogging with my Transylvanian Hound (Brady) for long distances at fast speeds in hot temperatures. I'm ${ryanAgeInYears} years old and my dog is ${bradyAgeInYears} years old.`,
            },
            {
                text: `For as long as I can remember, I've deeply enjoyed creating and organizing. It started as a kid with creating and organizing a wide variety of extracurriculars in high school, which evolved into creating and organizing a couple of local small businesses, which taught me to create and organize websites and creative tech projects for clients.`,
                image: require('../../assets/images/biography/Miami_Hack_Week_2023_DAO_House_Marriage_DAO_photo_on_Ryan_Zernach_Website_mobile_apps_react_Native.png'),
                imageTitle: 'Miami Hack Week 2023 DAO House Marriage DAO',
                imageCaption: `Here is our team during Miami Hack Week 2023, which is an Annual Hackathon with hundreds of participants!`,
            },
            {
                text: `Over a decade ago, after graduating from high school, I studied Computer Science at university, and then created/organized my own landscape design business (instaSod.com) and professional Christmas lighting business (CustomizedChristmasLights.com). I was financially independent for three years because of it!`,
                image: require('../../assets/images/biography/Ryan_Zernach_Granada_Spain_Alhambra_Palace.png'),
                imageTitle: '',
            },
            {
                text: `Although I enjoyed running my own small businesses, I felt as if I was being drawn back to the tech/software industry due to software's economies of scale. I can now solve problems at a greater scale. That's what led me to Lambda School (now known as BloomTech), where I learned Python, databases, and machine learning.`,
                image: require('../../assets/images/biography/Ryan-Zernach-with-Dog-Cali-Georgia-Landscape-Laptop-Tech-Nerd.png'),
                imageTitle: '',
                imageCaption: `In my free time, I enjoy programming my personal projects and learning about STEM research & technologies — such as sustainability, teampower, energetics, & medicine.`,
            },
            {
                text: `Upon completing the curriculum, I remotely worked as a part-time iOS/Android/Web freelance developer and as a part-time teaching assistant for data science & web development students from dozens of reknowned universities across the nation. While helping my students excel in their courses, I learned Javascript on-the-job, and started building mobile apps.`,
                image: require('../../assets/images/biography/Ryan_Zernach_visits_Seattle_but_Home_Is_Miami_Beach_Florida_Mobile_Apps_Deveveloper_Engineer_Remote_Software.png'),
                imageTitle: '',
                imageCaption: ``,
            },
            {
                text: `After being paid to publish my first iOS/Android app on the app stores, my thirst to build more mobile apps quickly became insatiable. Throughout all codebases that I develop, I enjoy reducing complexity/repetitiveness and increasing consistency/explicitness. I am grateful for my mentors and the ability to share knowledge with others from all over the world.`,
                video: "https://www.youtube.com/embed/8F0qOAfj_OM",
                imageTitle: '',
                imageCaption: 'This outdated video states that I am a Data Scientist & Cloud-Based Solutions Engineer, which is what I studied while at Lambda School (now known as BloomTech). However, my skillset & passion has reached into the field of Mobile App Development.',
            },
        ]
    }, [
        bradyAgeInYears,
        ryanAgeInYears,
        // yearsUntilForty
    ])

    const mindsetMattersMostGrid = useMemo(() => {
        return [
            {
                text: "DESIRE TO NEVER STOP LEARNING",
                icon: <GiBrain size={50} color={colors.blue} />
            },
            {
                text: "HUMILITY TO ASK FOR HELP",
                icon: <IoMdHelpBuoy size={50} color={colors.blue} />
            },
            {
                text: "CLEARLY COMMUNICATES",
                icon: <TfiComments size={50} color={colors.blue} />
            },
            {
                text: "DISCIPLINED & FOCUSED",
                icon: <GiTargetArrows size={50} color={colors.blue} />
            },
            {
                text: "EMPATHY FOR OTHERS",
                icon: <TfiThought size={50} color={colors.blue} />
            },
            {
                text: "ASKS PURPOSEFUL QUESTIONS",
                icon: <TbZoomQuestion size={50} color={colors.blue} />
            },
            {
                text: "GOAL-ORIENTED",
                icon: <TbTargetArrow size={50} color={colors.blue} />
            },
            {
                text: "RESULTS-DRIVEN ATTITUDE",
                icon: <GiNetworkBars size={50} color={colors.blue} />
            },
            {
                text: "CAPACITY TO RESEARCH",
                icon: <GiArchiveResearch size={50} color={colors.blue} />
            },
            {
                text: "MISSION & VISION DRIVEN",
                icon: <GiStairsGoal size={50} color={colors.blue} />
            },
            {
                text: "CRITICAL-THINKING SKILLS",
                icon: <CgSmartphoneChip size={50} color={colors.blue} />
            },
            {
                text: "TEAM-BUILDING APTITUDE",
                icon: <GiTeamUpgrade size={50} color={colors.blue} />
            },
        ]
    }, [])

    const booksWorthYourTimeGrid = useMemo(() => {
        return [
            {
                title: "Deep Work",
                author: "Cal Newport",
                link: 'https://g.co/kgs/mj1Gg7'
            },
            {
                title: "Rebooting AI",
                author: "Gary Marcus & Ernest Davis",
                link: 'https://g.co/kgs/uE5mB1'
            },
            {
                title: "Possible Mind: 25 Ways of Looking at AI",
                author: "John Brockman (and 25 others)",
                link: 'https://g.co/kgs/g8Sw3A'
            },
            {
                title: "How to Create A Mind",
                author: "Ray Kurzwell",
                link: 'https://g.co/kgs/imYssi'
            },
            {
                title: "How Successful People Lead",
                author: "John C. Maxwell",
                link: 'https://g.co/kgs/PLDANv'
            },
            {
                title: "Scale: Universal Laws of Growth, Innovation, Sustainability, and the Pace of Life in Organisms, Cities, Economies, and Companies",
                author: "Geoffrey West",
                link: 'https://g.co/kgs/5cNRiA'
            },
            {
                title: "Steve Jobs",
                author: "Walter Isaacson",
                link: 'https://g.co/kgs/T5pMMn'
            },
            {
                title: "Elon Musk",
                author: "Ashlee Vance",
                link: 'https://g.co/kgs/LH5jMc'
            },
            {
                title: "The Snowball (Warren Buffett)",
                author: "Alice Schroeder",
                link: 'https://g.co/kgs/Vp3uRW'
            },
            {
                title: "The Everything Store (Jeff Bezos)",
                author: "Brad Stone",
                link: 'https://g.co/kgs/xJer5H'
            },
            {
                title: "The 7 Habits of Highly Effective People",
                author: "Stephen R. Covey",
                link: 'https://g.co/kgs/vsaUmo'
            },
            {
                title: "Essentialism: Disciplined Pursuit of Life",
                author: "Greg McKeown",
                link: 'https://g.co/kgs/NfM3T3'
            },
            {
                title: "The Go Giver",
                author: "Bob Burg & John David Mann",
                link: 'https://g.co/kgs/JYJNYG'
            },
            {
                title: "The E Myth Revisited",
                author: "Michael E. Gerber",
                link: 'https://g.co/kgs/NPWD8i'
            },
            {
                title: "How to Win Friends & Influence People",
                author: "Dale Carnegie",
                link: 'https://g.co/kgs/96ZCpW'
            },
            {
                title: "The Tech Entrepreneur's Survival Guide",
                author: "Bernd Schoner",
                link: 'https://g.co/kgs/yE18QQ'
            },
            {
                title: "The Lean Startup",
                author: "Eric Ries",
                link: 'https://g.co/kgs/MeLRph'
            },
            {
                title: "The Four: Amazon, Apple, Google, Facebook",
                author: "Scott Galloway",
                link: 'https://g.co/kgs/9Fh26J'
            },
            {
                title: "Marriageology",
                author: "Belinda Luscombe",
                link: 'https://g.co/kgs/CqwnC6'
            },
            // {
            //     title: "The Purpose Driven Life",
            //     author: "Rick Warren",
            //     link: 'https://g.co/kgs/2vxYL7'
            // },
            {
                title: "Measure What Matters",
                author: "John Doerr",
                link: 'https://g.co/kgs/xzizqb'
            },
        ]
    }, [])

    const roleModelsGrid = useMemo(() => {
        return [
            {
                name: "Elon Musk",
                description: "problem solver, futurist, leader, renewable energy advocate, serial inventor",
            },
            {
                name: "Leonardo DiCaprio",
                description: "environmentalist, United Nations Ambassador of Peace, neurotech investor, film producer",
            },
            {
                name: "Oprah Winfrey",
                description: "entrepreneur, influential philanthropist, producer, author, one who lifts others up",
            },
            {
                name: "Steve Jobs",
                description: "an uncanny ability to simplify the user experience and to present the future of technology",
            },
            {
                name: "Barack Obama",
                description: "inspirational speaker, trailblazer, equality public figure, Nobel Peace Prize recipient, Former US President",
            },
            {
                name: "Bill & Melinda Gates",
                description: "use their success in the tech industry to generate an extremely positive force in the world",
            }
        ]
    }, [])

    const neverStopExploringImages = useMemo(() => {
        return [
            {
                image: require('../../assets/images/biography/exploring/North_Cascades_National_Park_Washington_Seattle-2240x684x0x88x2240x508x1668739896.png'),
                title: 'North Cascades National Park, Washington',
            },
            {
                image: require('../../assets/images/biography/exploring/Alhambra_of_Granada_Spain-2240x672x0x82x2240x508x1668739761.png'),
                title: 'Alhambra of Granada, Spain',
            },
            {
                image: require('../../assets/images/biography/exploring/Bryce-Canyon-National-Park-Panorama-Ryan-Zernach-e1599426647649-2240x396x0x0x2240x396x1599426647.jpg'),
                title: 'Bryce Canyon National Park, Utah',
            },
            {
                image: require('../../assets/images/biography/exploring/Exploring-North-Georgia-Mountains-Ryan-Zernach-e1599426616947-2240x374x0x0x2240x374x1599426616.jpg'),
                title: 'North Georgia Mountains',
            },
            {
                image: require('../../assets/images/biography/exploring/Miami_Beach_South_Point_Florida_Palm_Trees_Cruise_Ships-2240x540x0x16x2240x508x1607885195.jpg'),
                title: 'South Pointe Park of Miami Beach, Florida',
            },
            {
                image: require('../../assets/images/biography/exploring/Miami_Sunset_Miami_Beach-2240x528x0x10x2240x508x1668739698.png'),
                title: 'Biscayne Bay at Flamingo of Miami Beach, Florida',
            },
            {
                image: require('../../assets/images/biography/exploring/Plaza_de_Espana_Seville_Spain-2240x700x0x96x2240x508x1668739821.png'),
                title: 'Plaza de Espana, Seville, Spain',
            },
            {
                image: require('../../assets/images/biography/exploring/Seattle_Mountains_Winter_Snow_Trees-2240x532x0x12x2240x508x1668739950.png'),
                title: 'Mt. Baker, 10,000+ ft, Seattle, Washington',
            }
        ]
    }, [])

    return {
        ryanAgeInYears,
        bradyAgeInYears,
        mindsetMattersMostGrid,
        booksWorthYourTimeGrid,
        roleModelsGrid,
        neverStopExploringImages,
        // yearsUntilForty,
        introductionBio,
    }
}